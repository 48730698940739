<template>
    <Header componentName="parties"></Header>
    <section class="parties-banner style-1">
        <img class="bg-header-star" src="@/assets/images/parties/bg-header-star.png" alt="">
        <div class="container text-center position-relative">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <h1 class="fs-60 tt-capitalize mb-20">At kids fun hut your kid's party will include</h1>
                    <p class="mb-0">Here’s how we make your kid’s birthday party a day they will never forget</p>
                </div>
            </div>
        </div>
    </section>

    <section class="parties-text-section theme-color spacing__x over-hidden">
        <div class="container position-relative">
            <img class="element element-star" src="@/assets/images/elements/star-element.png" alt="">
            <img class="element element-2" src="@/assets/images/elements/element-2.png" alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <img src="@/assets/images/parties/parties-pictures/01.png" alt="">
                </div>
                <div class="col-lg-6">

                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">NO STRESS!</span>
                        <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">WE WILL DO THE PLANNING. YOU WILL JUST ENJOY!
                        </h4>
                        <p class="regular-color-1">Birthday Parties are instantly a hit if you are just there to enjoy and
                            eat the cake. And that is exactly what we have in mind for you. Plan your birthday parties with
                             Kids Fun Hut and you don’t need to worry about a thing. Just let us know about your day and how
                            many people you are expecting. We will do the rest. You will just have come to enjoy and watch
                            your kids and their friends have the time of their lives. </p>
                    </div>
                </div>
            </div>

        </div>
        <div class="container position-relative">
            <img class="element star-element-2" src="@/assets/images/elements/star-element-2.png" alt="">
            <img class="element element-5" src="@/assets/images/elements/element-5.png" alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                            DEDICATED BIRTHDAY ROOMS
                        </span>
                        <p class="regular-color-1">Planning and executing and birthday party is a tough job. Especially if
                            it is your child’s birthday. And the moment the Party of  leave, you are left with a load of mess
                            and dirty dishes. If you, however, plan your birthday party at Kids Fun Hut, we will give you an
                            experience just as if you had done it at your own place. Our experienced staff and Kids Fun Hut
                            manage every birthday party as if it's their own. Got some special arrangements to be made? We
                            will do that as well. Just drop us your requirements through email and we will make sure to make
                            your party as good as possible.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <img src="@/assets/images/parties/parties-pictures/02.png" alt="">
                </div>
            </div>

        </div>
        <div class="container position-relative">
            <img class="element element-4" src="@/assets/images/elements/element-4.png" alt="">
            <img class="element element-3" src="@/assets/images/elements/element-3.png" alt="">
            <div class="row align-items-center mb-40">
                <div class="col-lg-6">
                    <img src="@/assets/images/parties/parties-pictures/03.png" alt="">
                </div>
                <div class="col-lg-6">
                    <div class="mb-30">
                        <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                            UNLIMITED FUN
                        </span>
                        <p class="regular-color-1">An average birthday party lasts about two hours. But since every kid
                            wants his/her birthday to never end, we offer 2.5 hours of entertainment per package. And it
                            won’t be just the kids who are going to have fun at Kids Fun Hut. We expected people from
                            different age groups so there is something for everyone. Trust us, you won’t believe how quick
                            the time flies once you are at Kids Fun Hut.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="spacing__x-150 barrier-birthday-section">
        <div class="container position-relative">
            <img class="element star-element-2" src="@/assets/images/elements/star-element-2.png" alt="">
            <img class="element star-element-3" src="@/assets/images/elements/star-element-3.png" alt="">
            <img class="element star-element-4" src="@/assets/images/elements/star-element-4.png" alt="">
            
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">
                        SWIFT BOOKING AVAILABILITY</span>
                    <h4 class="mb-20 heading-color-1 fs-30 tt-capitalize">
                        BOOK YOUR PARTY. JUST LET US KNOW A WEEK BEFORE
                    </h4>
                    <p class="regular-color-1 mb-20">Although kids birthday parties are planned months before, we do
                        understand some people don’t get the time to make proper arrangements and reservations. This is why,
                        we at Kids Fun Hut offer birthday parties and arragnements with just a 7-DAY gap. Book your party
                        with us just a week before and you will have things ready and in place on your booking day.
                        <br>
                        We are always open <b>Seven Days A Week, 365 Days A Year</b> Just for You
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src="@/assets/images/parties/parties-pictures/birthday.jpg" alt="">
                </div>
            </div>
        </div>
    </section>

    <!-- Birthday party packages -->
    <section class="spacing__x pt-0">
        <div class="container-fluid">
            <div class="text-center mb-30">
                <span class="color-secondary-2 fw-700 mb-10 d-inline-block tt-capitalize">Packages</span>
                <h4 class="mb-10 heading-color-1 fs-40 tt-capitalize">Birthday Party Packages</h4>
                <p class="heading-color-1 mb-10 fs-20">Online bookings available only between 7-45 days in advance</p>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40  w-100 text-center position-relative z-index-1">10 CHILDREN <br /> PARTY
                                <span>${{party[partyEnums.TenChildrenParty] ?? 389}}</span>
                            </h4>
                        </div>
                        <div class="price-content">
                            <h6 class="fs-25">PARTY OF 10</h6>
                            <p class="regular-color-1">10 Kids ($15 for any additional child) </p>
                            <p class="regular-color-1">10 adults ($7 for any additional adult)</p>
                            <h6 class="fs-25">FOOD</h6>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/1.png" alt=""> 3L Pizzas</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/2.png" alt=""> 10" Cake</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/3.png" alt=""> 10 Water bottles</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/4.png" alt=""> 10 Juices</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/5.png" alt=""> Plates</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/6.png" alt=""> Cutlery</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/7.png" alt=""> Napkins</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/8.png" alt=""> Unlimited coffee</p>
                            <h6 class="fs-25">SERVICE</h6>
                            <p class="regular-color-1">2 hours of ultimate Birthday joy with 30 minutes extra time to play</p>
                            <p class="regular-color-1">Ninja Course</p>
                            <p class="regular-color-1">Climbing Wall</p>
                            <p class="regular-color-1">Zip-line</p>
                            <p class="regular-color-1">Soft Play</p>
                            <p class="regular-color-1">Soccer & Basket-Ball Court</p>
                            <p class="regular-color-1">Toddler Area</p>
                            <router-link to="/shop/book-party" class="c-btn">Book Now</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="package-box style2">
                        <div class="price">
                            <h4 class="fs-40  w-100 text-center position-relative z-index-1">15 CHILDREN <br /> PARTY
                                <span>${{party[partyEnums.FifteenChildrenParty] ?? 489}}</span>
                            </h4>
                        </div>
                        <div class="price-content">
                            <h6 class="fs-25">PARTY OF 15</h6>
                            <p class="regular-color-1">15 Kids ($15 for any additional child) </p>
                            <p class="regular-color-1">15 adults ($7 for any additional adult)</p>
                            <h6 class="fs-25">FOOD</h6>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/1.png" alt=""> 4L pizzas</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/2.png" alt=""> 10" Cake</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/3.png" alt=""> 15 Water bottles</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/4.png" alt=""> 15 Juices</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/5.png" alt=""> Plates</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/6.png" alt=""> Cutlery</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/7.png" alt=""> Napkins</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/8.png" alt=""> Unlimited coffee</p>
                            <h6 class="fs-25">SERVICE</h6>
                            <p class="regular-color-1">2 hours of ultimate Birthday joy with 30 minutes extra time to play</p>
                            <p class="regular-color-1">Ninja Course</p>
                            <p class="regular-color-1">Climbing Wall</p>
                            <p class="regular-color-1">Zip-line</p>
                            <p class="regular-color-1">Soft Play</p>
                            <p class="regular-color-1">Soccer & Basket-Ball Court</p>
                            <p class="regular-color-1">Toddler Area</p>
                            <router-link to="/shop/book-party" class="c-btn">Book Now</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="package-box style1">
                        <div class="price">
                            <h4 class="fs-40  w-100 text-center position-relative z-index-1">20 CHILDREN <br /> PARTY
                                <span>${{party[partyEnums.TwentyChildrenParty] ?? 589}}</span>
                            </h4>
                        </div>
                        <div class="price-content">
                            <h6 class="fs-25">PARTY OF 20</h6>
                            <p class="regular-color-1">20 Kids ($15 for any additional child) </p>
                            <p class="regular-color-1">20 adults ($7 for any additional adult)</p>
                            <h6 class="fs-25">FOOD</h6>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/1.png" alt=""> 5L Pizzas</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/2.png" alt=""> 12"X16" Sheet Cake</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/3.png" alt=""> 20 Water bottles</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/4.png" alt=""> 20 Juices</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/5.png" alt=""> Plates</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/6.png" alt=""> Cutlery</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/7.png" alt=""> Napkins</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/8.png" alt=""> Unlimited coffee</p>
                            <h6 class="fs-25">SERVICE</h6>
                            <p class="regular-color-1">2 hours of ultimate Birthday joy with 30 minutes extra time to play</p>
                            <p class="regular-color-1">Ninja Course</p>
                            <p class="regular-color-1">Climbing Wall</p>
                            <p class="regular-color-1">Zip-line</p>
                            <p class="regular-color-1">Soft Play</p>
                            <p class="regular-color-1">Soccer & Basket-Ball Court</p>
                            <p class="regular-color-1">Toddler Area</p>
                            <router-link to="/shop/book-party" class="c-btn">Book Now</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="package-box style2">
                        <div class="price">
                            <h4 class="fs-40  w-100 text-center position-relative z-index-1">30 CHILDREN <br /> PARTY
                                <span>${{party[partyEnums.ThirtyChildrenParty] ?? 789}}</span>
                            </h4>
                        </div>
                        <div class="price-content">
                            <h6 class="fs-25">PARTY OF 30</h6>
                            <p class="regular-color-1">30 Kids ($15 for any additional child) </p>
                            <p class="regular-color-1">30 adults ($7 for any additional adult)</p>
                            <h6 class="fs-25">FOOD</h6>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/1.png" alt=""> 8L Pizzas</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/2.png" alt=""> 12"X16" Sheet Cake</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/3.png" alt=""> 30 Water bottles</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/4.png" alt=""> 30 Juices</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/5.png" alt=""> Plates</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/6.png" alt=""> Cutlery</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/7.png" alt=""> Napkins</p>
                            <p class="regular-color-1"><img src="@/assets/images/parties/icons/8.png" alt=""> Unlimited coffee</p>
                            <h6 class="fs-25">SERVICE</h6>
                            <p class="regular-color-1">2 hours of ultimate Birthday joy with 30 minutes extra time to play</p>
                            <p class="regular-color-1">Ninja Course</p>
                            <p class="regular-color-1">Climbing Wall</p>
                            <p class="regular-color-1">Zip-line</p>
                            <p class="regular-color-1">Soft Play</p>
                            <p class="regular-color-1">Soccer & Basket-Ball Court</p>
                            <p class="regular-color-1">Toddler Area</p>
                            <router-link to="/shop/book-party" class="c-btn">Book Now</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue';
import Footer from './layouts/Footer.vue';
import { Activities } from '../apiAgent';
import { SlugEnum } from '../enums/index';
export default {
    name: 'Parties',
    components: {
        Header,
        Footer
    },
    data: () => ({
        party: {},
        partyEnums : {...SlugEnum}
    }),
    mounted() {
        this.getTags();
    },
    methods: {
        getTags() {
            this.$store.dispatch('loader/setLoader', true);
            Activities.GetTags()
                .then(({ data }) => {
                    const bookParty = data.data.find(x => x.slug === SlugEnum.BookParty);
                    if (bookParty) {
                        for (const _party of bookParty.active_activities) {
                            this.party[`${_party.slug}`] = _party.price;
                        }
                    }
                    this.$store.dispatch('loader/setLoader', false);
                })
                .catch((error) => {
                    this.$store.dispatch('loader/setLoader', false);
                    console.error(error);
                });
        }
    }
}
</script>