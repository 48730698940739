<template>
  <Header></Header>

	<section class="spacing__x f-header ninja-text-section pb-40 over-hidden">
		<img class="element element-3" src="@/assets/images/elements/element-3.png" alt="" />
		<div class="container">
			<div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="fs-60 heading-color-1">Kids Fun Hut Memberships</h1>
                    </div>
                </div>
            </div>
			<div class="row">
				<div class="col-lg-8">
					<div class="mb-30">
						<span class="color-secondary-2 fw-700 mb-10 d-inline-block"> Membership</span>
						<h2 class="mb-20 heading-color-1 fs-30">
							Become a member and get exclusive discounts and offers tailored for different needs
						</h2>
						<span class="color-secondary-2 fw-700 mb-10 d-inline-block">Under 4 years</span>
						<div class="count-listing">
							<p class="heading-color-1"><span>1</span>One Month Membership of 6-Visits for only $65 CAD</p>
							<p class="heading-color-1"><span>2</span>One Month Membership of 8-Visits for only $80 CAD</p>
							<p class="heading-color-1"><span>2</span>One Month Membership of 10-Visits for only $90 CAD</p>
							<p class="heading-color-1"><span>2</span>Three Month Membership of 15-Visits for only $150 CAD</p>
						</div>
					</div>

					<span class="color-secondary-2 fw-700 mb-10 d-inline-block">
						4 years and above
					</span>
					<div class="count-listing">
						<p class="heading-color-1"><span>1</span>One Month Membership of 10-Visits for only $140 CAD</p>
						<p class="heading-color-1"><span>2</span>Three Month Membership of 15-Visits for only $225 CAD</p>
					</div>
					<span class="color-secondary-2 fw-700 mb-10 d-inline-block">Terms & Conditions</span>
					<div class="row">
						<div class="col-lg-6">
							<div class="count-listing">
								<p class="heading-color-1"><span>1</span> A valid photo etc.</p>
								<p class="heading-color-1"><span>2</span> The pass can only be used by one child, validated at Check-In.</p>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="count-listing">
								<p class="heading-color-1 align-items-start">
									<span>3</span>
									The pass in non-refundable and non-transferable.
								</p>
								<p class="heading-color-1">
									<span>4</span>
									The pass starts and the day of purchase.
								</p>
							</div>
						</div>
					</div>
					<div class="btn-list mb-20">
						<router-link class="c-btn style-3" to="/shop/memberships">Buy your Pass Now</router-link>
					</div>
				</div>
				<div class="col-lg-4">
					<img class="right-side" src="@/assets/images/membership.png" alt="" />
				</div>
			</div>
		</div>
	</section>

  <Footer></Footer>
</template>
  
<script>
import Header from './layouts/Header.vue'
import Footer from './layouts/Footer.vue'
export default {
  name: 'Memberships',
  components: {
    Header,
    Footer
  }
}
</script>