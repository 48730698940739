<template>
    <Loader v-if="$store.getters['loader/isLoader']" />
    <router-view></router-view>
</template>
<script>
import Loader from './components/Helpers/Loader.vue';
export default {
    name: 'App',
    components: {
        Loader,
    },
    data(){
        return {
            version : process.env.VUE_APP_VERSION,
            cachedVersion : localStorage.getItem('app_version')
        };
    },
    mounted() {
        this.checkVersion();
    },
    methods: {
        checkVersion() {
            if(this.version !== this.cachedVersion) {
                localStorage.setItem('app_version', this.version);    
                window.location.reload(true);
                console.info('Cache Cleared!');
            }
        }
    }
}
</script>
